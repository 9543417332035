import {
  Button,
  ButtonGroup,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  FormFeedback
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import {
  CallPerson,
  CallSettings,
  useAddCallResultMutation,
  useGetCallPersonsQuery,
  useGetCallReasonsByProfileIdQuery,
  useGetCallSettingsQuery
} from 'src/services/calling'
import { useFormik } from 'formik'
import { useGetProfileQuery } from 'src/services/profiles'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import { toast } from 'react-toastify'
import { useGetPhonesQuery } from 'src/services/phones'
import { LIST_LIMIT } from 'src/client'
import { useState, useEffect } from 'react'

export const CallResultModal = ({
  loan_id,
  profile_id,
  phone = '',
  open,
  close,
  isOpen,
  ...props
}: ReturnType<typeof useDisclosure> & {
  loan_id: string
  profile_id: string
  phone?: string
  [key: string]: any
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data: profile } = useGetProfileQuery(profile_id!, {
    skip: profile_id == null
  })

  const { data: persons = [] } = useGetCallPersonsQuery()

  const [selectedPerson, setSelectedPerson] = useState<CallPerson | null>(null)
  const [selectedResultSetting, setSelectedResultSetting] =
    useState<CallSettings | null>(null)

  const [contactType, setContactType] = useState('')
  const [resultType, setResultType] = useState('')

  useEffect(() => {
    if (persons.length > 0 && !contactType) {
      const initialContactType = persons[0].name
      setContactType(initialContactType)
    }
  }, [persons, contactType])

  useEffect(() => {
    const person = persons.find((p) => p.name === contactType)
    setSelectedPerson(person || null)
  }, [contactType, persons])

  const { data: results = [] } = useGetCallSettingsQuery(
    selectedPerson?.type_id!,
    {
      skip: !selectedPerson
    }
  )

  useEffect(() => {
    if (results.length > 0) {
      const initialResultType = results.find((r) => r.is_default)?.result_type
      setResultType(initialResultType || '')
    } else {
      setResultType('')
    }
  }, [results])

  useEffect(() => {
    const resultSetting = results.find((r) => r.result_type === resultType)
    setSelectedResultSetting(resultSetting || null)
  }, [resultType, results])

  const { data: reasons = [] } = useGetCallReasonsByProfileIdQuery()

  const { data: phones = [] } = useGetPhonesQuery(
    {
      client_id: profile?.client.id,
      limit: LIST_LIMIT,
      offset: 0
    },
    { skip: profile == null }
  )

  const [addCallResult, { error, isLoading }] = useAddCallResultMutation()

  const getValidationSchema = (selectedResultSetting: CallSettings | null) => {
    const schemaFields: any = {
      contact_type: Yup.string().required(t('Required')),
      result: Yup.string().required(t('Required')),
      phone: Yup.string().required(t('Required')),
      reason: Yup.string(),
      amount_promise: Yup.number()
        .typeError(t('Amount must be a number'))
        .nullable(),
      date_promise: Yup.date().nullable().typeError(t('Required')),
      comment: Yup.string().nullable()
    }

    if (selectedResultSetting) {
      if (selectedResultSetting.need_reason) {
        schemaFields.reason = Yup.string()
          .required(t('Required'))
          .notOneOf(['Нет причины'], t('Required'))
      }

      if (selectedResultSetting.need_promise_amount) {
        schemaFields.amount_promise = Yup.number()
          .typeError(t('Amount must be a number'))
          .required(t('Required'))
      }

      if (selectedResultSetting.need_promise_date) {
        schemaFields.date_promise = Yup.date()
          .typeError(t('Required'))
          .notOneOf([null], t('Required'))
      }

      if (selectedResultSetting.need_comment) {
        schemaFields.comment = Yup.string().required(t('Required'))
      }
    }

    return Yup.object(schemaFields)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      profile_id,
      loan_id,
      contact_type: contactType,
      result: resultType,
      reason: 'Нет причины',
      amount_promise: '',
      date_promise: null,
      comment: '',
      phone: phone || profile?.phone || ''
    },
    validationSchema: getValidationSchema(selectedResultSetting),
    validateOnMount: false,
    onSubmit: async (values) => {
      const {
        profile_id,
        loan_id,
        contact_type,
        result,
        reason,
        amount_promise,
        date_promise,
        comment,
        phone
      } = values
      const resultData: any = {
        loan_id,
        profile_id,
        contact_type,
        result,
        phone
      }
      resultData.contact_type = getIdFromName(persons, contact_type)
      resultData.result = getIdFromResultType(results, result)

      if (reason && reason !== 'Нет причины') {
        resultData.reason = getIdFromName(reasons, reason)
      }

      if (amount_promise) {
        resultData.amount_promise = amount_promise
      }

      if (date_promise) {
        resultData.date_promise = `${date_promise}T00:00:00`
      }

      if (comment) {
        resultData.comment = comment
      }

      resultData.is_order = !!props.isOrder

      if (props.collectionQueueSeq) {
        resultData.collection_queue_id = props.collectionQueueSeq
      }

      try {
        await addCallResult(resultData).unwrap()
        toast.success(t('Successfully added'), {
          autoClose: 2000,
          closeOnClick: false
        })
        if (props.collectionQueueSeq) {
          navigate('/debts-queue')
        }
      } catch (error) {
        toast.error(t('Error adding'), {
          autoClose: 2000,
          closeOnClick: false
        })
      }
    }
  })

  const autoResizeInput = (event: any) => {
    event.target.style.height = '40px'
    event.target.style.height = event.target.scrollHeight + 'px'
  }

  const header = (
    <div className='d-flex flex-row justify-content-between w-100 p-3'>
      <div className='modal-title'>
        <span>{t('Fill out call result')}</span>
      </div>
    </div>
  )

  if (!isOpen) {
    return (
      <div
        id='myModal'
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          top: 200,
          zIndex: 1005
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={open}>
            <small className='fw-medium text-decoration-underline'>
              {t('expand')}
            </small>
          </div>
          {header}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          top: 50,
          zIndex: 1005
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={close}>
            <small className='fw-medium text-decoration-underline'>
              {t('collapse')}
            </small>
          </div>
          <ModalHeader>
            {t('Recording the result of a conversation with')}
            <br />
            <Link
              className='btn-md pt-0 text-decoration-underline'
              to={`/client-view?id=${profile?.client.id}`}
            >
              {`${profile?.client?.last_name} ${profile?.client?.first_name} ${profile?.client?.middle_name}`}
            </Link>
          </ModalHeader>
          <div className='h-divider' />
          <ModalBody
            style={{
              overflow: 'auto',
              paddingTop: 0
            }}
          >
            <form
              className='vstack d-flex align-items-center justify-content-center p-2'
              onSubmit={formik.handleSubmit}
            >
              <div
                className='vstack mt-2 mb-0 align-self-center'
                style={{ width: '350px' }}
              >
                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'contact_type'}>
                    {t('Who were you talking to?')}
                  </Label>
                  <Input
                    id={'contact_type'}
                    name='contact_type'
                    value={formik.values.contact_type}
                    type={'select'}
                    onChange={(e) => {
                      setContactType(e.target.value)
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.contact_type &&
                      !!formik.errors.contact_type
                    }
                    disabled={isLoading}
                  >
                    {persons.length > 0 ? (
                      persons.map((person) => (
                        <option key={person.name} value={person.name}>
                          {person.name}
                        </option>
                      ))
                    ) : (
                      <option value=''>{t('Loading...')}</option>
                    )}
                  </Input>
                  {formik.touched.contact_type && (
                    <FormFeedback>{formik.errors.contact_type}</FormFeedback>
                  )}
                </div>

                <div style={{ marginBottom: '16px' }}>
                  <Label htmlFor={'call_result'}>{t('Call result')}</Label>
                  <Input
                    id='result'
                    name='result'
                    value={formik.values.result}
                    type={'select'}
                    onChange={(e) => {
                      setResultType(e.target.value)
                      formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.result && !!formik.errors.result}
                    disabled={isLoading || results.length === 0}
                  >
                    {results.length > 0 ? (
                      results.map((result) => (
                        <option key={result.id} value={result.result_type}>
                          {result.result_type}
                        </option>
                      ))
                    ) : (
                      <option value=''>{t('No results available')}</option>
                    )}
                  </Input>
                  {formik.touched.result && (
                    <FormFeedback>{formik.errors.result}</FormFeedback>
                  )}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor='phone'>{t('phone')}</Label>
                  <Input
                    id='phone'
                    name='phone'
                    value={formik.values.phone}
                    type='select'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.phone && !!formik.errors.phone}
                    disabled={isLoading}
                  >
                    <option value={profile?.phone}>{profile?.phone}</option>
                    {phones.map(({ phone }) => (
                      <option key={phone} value={phone}>
                        {phone}
                      </option>
                    ))}
                  </Input>
                  {formik.touched.phone && (
                    <FormFeedback>{formik.errors.phone}</FormFeedback>
                  )}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'reason'}>{t('Reason')}</Label>
                  <Input
                    id={'reason'}
                    name='reason'
                    value={formik.values.reason}
                    type={'select'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.reason && !!formik.errors.reason}
                    disabled={isLoading}
                  >
                    <option value='Нет причины'>{t('No reason')}</option>
                    {reasons.map((reason) => (
                      <option key={reason.id} value={reason.name}>
                        {reason.name}
                      </option>
                    ))}
                  </Input>
                  {formik.touched.reason && (
                    <FormFeedback>{formik.errors.reason}</FormFeedback>
                  )}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'comment'}>{t('Comment')}</Label>
                  <Input
                    id={'comment'}
                    name='comment'
                    value={formik.values.comment}
                    style={{ height: '40px' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onInput={autoResizeInput}
                    type={'textarea'}
                    invalid={formik.touched.comment && !!formik.errors.comment}
                    disabled={isLoading}
                  />
                  {formik.touched.comment && (
                    <FormFeedback>{formik.errors.comment}</FormFeedback>
                  )}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'amount_promise'}>
                    {t('amount_promise')}
                  </Label>
                  <Input
                    id={'amount_promise'}
                    name='amount_promise'
                    value={formik.values.amount_promise}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.amount_promise &&
                      !!formik.errors.amount_promise
                    }
                    disabled={isLoading}
                  />
                  {formik.touched.amount_promise && (
                    <FormFeedback>{formik.errors.amount_promise}</FormFeedback>
                  )}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label>{t('date_promise')}</Label>
                  <Flatpickr
                    className='form-control'
                    id='date_promise'
                    name='date_promise'
                    value={formik.values.date_promise}
                    options={{
                      minDate: 'today',
                      dateFormat: 'Y-m-d',
                      onChange: (_: unknown, dateStr: string) => {
                        formik.setFieldValue('date_promise', dateStr)
                      }
                    }}
                    disabled={isLoading}
                  />
                  {formik.touched.date_promise && (
                    <FormFeedback style={{ display: 'block' }}>
                      {formik.errors.date_promise}
                    </FormFeedback>
                  )}
                </div>

                {error && <DangerAlert error={error} />}
              </div>
              <ButtonGroup>
                <Button
                  style={{ marginLeft: '10px' }}
                  className='mt-3'
                  type='submit'
                  disabled={isLoading}
                >
                  {t('Send')}
                </Button>
              </ButtonGroup>
            </form>
          </ModalBody>
        </div>
      </div>
    </>
  )
}

const getIdFromName = (list: any[], name: string) =>
  list.find((item) => item.name === name)?.type_id

const getIdFromResultType = (list: any[], result_type: string) =>
  list.find((item) => item.result_type === result_type)?.result_type_id
